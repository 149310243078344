import { template as template_d8d0c0c8b2e64c1c89488bbde39925ed } from "@ember/template-compiler";
const WelcomeHeader = template_d8d0c0c8b2e64c1c89488bbde39925ed(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
