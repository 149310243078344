import { template as template_d822085170814ff2b402f93e328affbe } from "@ember/template-compiler";
const EmptyState = template_d822085170814ff2b402f93e328affbe(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
