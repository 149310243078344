import { template as template_0cc7004d40664489b7938db10f7853ce } from "@ember/template-compiler";
const FKControlMenuContainer = template_0cc7004d40664489b7938db10f7853ce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
